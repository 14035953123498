import React, { useState, useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@videojs/http-streaming';
import 'videojs-contrib-quality-levels';
import './App.css';

const Player = ({ stream }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (isReady && stream && videoRef.current && !playerRef.current) {
      const player = videojs(videoRef.current, {
        autoplay: true,
        controls: true,
        fluid: true,
        liveui: true,
        inactivityTimeout: 0,
        muted: true,
        html5: {
          hls: {
            overrideNative: true
          },
          nativeAudioTracks: false,
          nativeVideoTracks: false
        }
      });
      playerRef.current = player;
    }

    if (playerRef.current && stream) {
      const type = stream.url.endsWith('.m3u8') ? 'application/x-mpegURL' : 'application/dash+xml';
      playerRef.current.src({ src: stream.url, type: type });
      playerRef.current.play().catch(error => {
        console.error('Error attempting to play:', error);
      });
    }
  }, [isReady, stream]);

  return (
    <div className="video-container-wrapper">
      <div className="video-container fluid">
        <div data-vjs-player>
          <video ref={videoRef} className="video-js vjs-default-skin video-player" />
        </div>
      </div>
    </div>
  );
};

export default Player;