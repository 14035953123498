import React from 'react';
import PlayBtnIcon from './libs/bootstrap-icons-1.11.3/play-circle.svg';
import TrashIcon from './libs/bootstrap-icons-1.11.3/trash.svg';
import PencilIcon from './libs/bootstrap-icons-1.11.3/pencil-square.svg';
import EyeIcon from './libs/bootstrap-icons-1.11.3/eye.svg';
import EyeSlashIcon from './libs/bootstrap-icons-1.11.3/eye-slash.svg';

const Dashboard = ({ 
  onAddStream, 
  onRemoveAll, 
  onPlayAll, 
  isSingleMode, 
  onToggleMode, 
  streams, 
  onLoadStream, 
  onDeleteStream, 
  onEditStream,
  activeStreams,
  selectedStream,
  pipsPerRow,
  onPipsPerRowChange
}) => {
  const isStreamActive = (stream) => {
    if (isSingleMode) {
      return selectedStream && selectedStream.id === stream.id;
    } else {
      return activeStreams.some(activeStream => activeStream.id === stream.id);
    }
  };

  return (
    <div className="row mt-3">
      <div className="col-12">
        <div className="card">
          <div className="card-body d-flex justify-content-between align-items-center flex-wrap">
            <div className="d-flex align-items-center mb-2">
              <button className="btn btn-outline-primary me-3" onClick={onAddStream}>
                Add Stream
              </button>
              <div className="dropdown me-3">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="streamSelectorDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Stream Selector
                </button>
                <ul className="dropdown-menu" aria-labelledby="streamSelectorDropdown">
                  {streams.map((stream, index) => (
                    <li key={stream.id}>
                      <div className="dropdown-item">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="me-3">{stream.title}</span>
                          <div>
                            <button className="btn btn-sm btn-link p-0 me-3" onClick={() => onLoadStream(stream)} title="Play">
                              <img src={PlayBtnIcon} alt="Play" className="icon icon-success" />
                            </button>
                            <button className="btn btn-sm btn-link p-0 me-3" onClick={() => onEditStream(stream)} title="Edit">
                              <img src={PencilIcon} alt="Edit" className="icon icon-primary" />
                            </button>
                            <button className="btn btn-sm btn-link p-0 me-3" onClick={() => onDeleteStream(stream.id)} title="Delete">
                              <img src={TrashIcon} alt="Delete" className="icon icon-danger" />
                            </button>
                            <img 
                              src={isStreamActive(stream) ? EyeIcon : EyeSlashIcon} 
                              alt={isStreamActive(stream) ? "Playing" : "Not playing"} 
                              className="icon icon-secondary" 
                              title={isStreamActive(stream) ? "Playing" : "Not playing"} 
                            />
                          </div>
                        </div>
                      </div>
                      {index < streams.length - 1 && <hr className="dropdown-divider" />}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              <div className="form-check form-switch me-3 mb-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="viewModeSwitch"
                  checked={!isSingleMode}
                  onChange={() => onToggleMode(!isSingleMode)}
                />
                <label className="form-check-label" htmlFor="viewModeSwitch">
                  {isSingleMode ? 'Singleview' : 'Multiview'}
                </label>
              </div>
              <div className="dropdown me-3 mb-2">
                <button
                  className="btn btn-outline-secondary dropdown-toggle"
                  type="button"
                  id="layoutEditorDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  disabled={isSingleMode}
                >
                  Layout Editor: {isSingleMode ? '1' : pipsPerRow}
                </button>
                <ul className="dropdown-menu" aria-labelledby="layoutEditorDropdown">
                  {[1,2,3,4,5,6,7,8,9,10].map(num => (
                    <li key={num}>
                      <button 
                        className="dropdown-item" 
                        onClick={() => onPipsPerRowChange(num)}
                      >
                        {num}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
              <button className="btn btn-outline-success me-3 mb-2" onClick={onPlayAll}>
                Play All
              </button>
              <button className="btn btn-outline-danger me-3 mb-2" onClick={onRemoveAll}>
                Remove All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;