import React, { useState } from 'react';
import Dashboard from './Dashboard';
import Player from './Player';
import './App.css';

const VideoApp = () => {
  const [showAddStreamForm, setShowAddStreamForm] = useState(false);
  const [newStreamTitle, setNewStreamTitle] = useState('');
  const [newStreamUrl, setNewStreamUrl] = useState('');
  const [streams, setStreams] = useState([]);
  const [activeStreams, setActiveStreams] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editedStream, setEditedStream] = useState(null);
  const [isSingleMode, setIsSingleMode] = useState(true);
  const [selectedStream, setSelectedStream] = useState(null);
  const [pipsPerRow, setPipsPerRow] = useState(3);

  const handleAddStream = () => {
    setShowAddStreamForm(true);
    setIsEditMode(false);
    setEditedStream(null);
  };

  const handleCloseAddStreamForm = () => {
    setShowAddStreamForm(false);
    setNewStreamTitle('');
    setNewStreamUrl('');
    setIsEditMode(false);
    setEditedStream(null);
  };

  const handlePlayAll = () => {
    setIsSingleMode(false);
    setActiveStreams(streams);
  };

  const handleTitleChange = (e) => {
    setNewStreamTitle(e.target.value);
  };

  const handleUrlChange = (e) => {
    setNewStreamUrl(e.target.value);
  };

  const handleEditStream = (stream) => {
    setIsEditMode(true);
    setEditedStream(stream);
    setNewStreamTitle(stream.title);
    setNewStreamUrl(stream.url);
    setShowAddStreamForm(true);
  };

  const handlePipsPerRowChange = (value) => {
    setPipsPerRow(Number(value));
  };
  

  const handleSaveStream = () => {
    if (newStreamTitle && newStreamUrl) {
      const isDuplicateTitle = isEditMode
        ? streams.some(
            (stream) =>
              stream.title === newStreamTitle && stream.id !== editedStream.id
          )
        : streams.some((stream) => stream.title === newStreamTitle);

      if (!isDuplicateTitle) {
        const newStream = {
          id: isEditMode ? editedStream.id : Date.now(),
          title: newStreamTitle,
          url: newStreamUrl
        };

        if (isEditMode) {
          const updatedStreams = streams.map((stream) =>
            stream.id === editedStream.id ? newStream : stream
          );
          setStreams(updatedStreams);
          setActiveStreams(prevActiveStreams =>
            prevActiveStreams.map(stream =>
              stream.id === editedStream.id ? newStream : stream
            )
          );
          if (selectedStream && selectedStream.id === editedStream.id) {
            setSelectedStream(newStream);
          }
        } else {
          setStreams([...streams, newStream]);
        }
        setNewStreamTitle('');
        setNewStreamUrl('');
        setShowAddStreamForm(false);
        setIsEditMode(false);
        setEditedStream(null);
      } else {
        alert('This name has already been taken. A unique name is required.');
      }
    }
  };

  const handleLoadStream = (stream) => {
    if (isSingleMode) {
      setSelectedStream(stream);
    } else {
      const existingStream = activeStreams.find(
        (s) => s.id === stream.id
      );
      if (!existingStream) {
        setActiveStreams([...activeStreams, stream]);
      }
    }
  };

  const handleDeleteStream = (streamId) => {
    setStreams(streams.filter(stream => stream.id !== streamId));
    setActiveStreams(activeStreams.filter(stream => stream.id !== streamId));
    if (selectedStream && selectedStream.id === streamId) {
      setSelectedStream(null);
    }
  };

  const handleRemoveActiveStream = (streamToRemove) => {
    setActiveStreams(activeStreams.filter(stream => stream.id !== streamToRemove.id));
  };

  const handleClearAllPlayers = () => {
    setActiveStreams([]);
    setSelectedStream(null);
  };

  const handleToggleMode = (singleMode) => {
    setIsSingleMode(singleMode);
    if (singleMode) {
      setActiveStreams([]);
    } else {
      setSelectedStream(null);
    }
  };

  return (
    <div className="container-fluid">
      <Dashboard
        onAddStream={handleAddStream}
        onRemoveAll={handleClearAllPlayers}
        onPlayAll={handlePlayAll}
        isSingleMode={isSingleMode}
        onToggleMode={handleToggleMode}
        streams={streams}
        onLoadStream={handleLoadStream}
        onDeleteStream={handleDeleteStream}
        onEditStream={handleEditStream}
        activeStreams={activeStreams}
        selectedStream={selectedStream}
        pipsPerRow={pipsPerRow}
        onPipsPerRowChange={handlePipsPerRowChange}
      />

<div className="row mt-3">
  <div className="col-lg-12">
    {isSingleMode ? (
      selectedStream && (
        <div className="single-player-wrapper">
          <Player stream={selectedStream} />
        </div>
      )
    ) : (
      <div 
        className="multiview-container" 
        style={{"--pips-per-row": pipsPerRow}}
      >
        {activeStreams.map((stream) => (
          <div key={stream.id} className="multiview-video-wrapper">
            <div className="card">
              <div className="card-body position-relative">
                <div className="card-title-with-close">
                  <h5 className="card-title">{stream.title}</h5>
                  <button
                    type="button"
                    className="btn-close btn-close-gray close-icon"
                    onClick={() => handleRemoveActiveStream(stream)}
                  ></button>
                </div>
                <div className="player-card-body">
                  <div className="player-inner-card-body">
                    <div className="player-container">
                      <Player stream={stream} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
</div>

{showAddStreamForm && (
        <div className="modal" style={{ display: 'block' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{isEditMode ? 'Edit Stream' : 'Add Stream'}</h5>
                <button
                  type="button"
                  className="btn-close btn-close-white"
                  onClick={handleCloseAddStreamForm}
                ></button>
              </div>
              <div className="modal-body">
              <div className="mb-3">
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder="Stream Name"
                    value={newStreamTitle}
                    onChange={handleTitleChange}
                  />
                </div>

                <div className="mb-3 placeholder-wrapper">
                  <input
                    type="text"
                    className="form-control custom-input"
                    placeholder=""
                    value={newStreamUrl}
                    onChange={handleUrlChange}
                  />
                  <span className="custom-placeholder-text">
                    HLS or DASH URL <i>(.m3u8, .mpd)</i>
                  </span>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseAddStreamForm}>
                  Cancel
                </button>
                <button type="button" className="btn btn-primary" onClick={handleSaveStream}>
                  {isEditMode ? 'Save Changes' : 'Save Stream'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoApp;