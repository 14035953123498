import React, { useState } from 'react';
import './LoginForm.css';

const LoginForm = ({ onLogin }) => {
  const [passphrase, setPassphrase] = useState('');
  const [rememberMe, setRememberMe] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    onLogin(passphrase, rememberMe);
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Simple Media Player</h2>
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          type="password"
          id="inputPassphrase"
          className="form-control"
          placeholder="Enter passphrase"
          value={passphrase}
          onChange={(e) => setPassphrase(e.target.value)}
          required
        />
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            id="rememberMe"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label className="form-check-label" htmlFor="rememberMe">
            Remember me
          </label>
        </div>
        <button className="btn btn-primary btn-block" type="submit">
          Enter
        </button>
      </form>
    </div>
  );
};

export default LoginForm;